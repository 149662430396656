/* eslint-disable import/exports-last */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unused-modules */
import { getHexColorFromNamed } from '@/helpers/utils/colors'
import { z } from 'zod'

function expandShortHandHexColor(color: string) {
  if (color.length !== 3) return color
  return color
    .split('')
    .map((char) => char.repeat(2))
    .join('')
}

function htmlColorTransform(color: unknown) {
  let parsedColor = color
  if (typeof color === 'number') parsedColor = color.toString()
  if (typeof parsedColor !== 'string') return parsedColor
  const lowerCaseColor = parsedColor.toLowerCase()
  const named = getHexColorFromNamed(lowerCaseColor)
  const withoutHash = named ? named.replace(/#/gi, '') : lowerCaseColor
  return expandShortHandHexColor(withoutHash)
}

export const slug = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z.string().regex(/^[0-9]+(?:[-\w]+)?$/i)
)

export const numericString = z.preprocess((v) => (typeof v === 'number' ? String(v) : v), z.string().regex(/[0-9]+/))
export const optionalNumericString = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z
    .string()
    .regex(/[0-9]+/)
    .optional()
    .or(z.literal(''))
)
export const hexColorString = z.string().regex(/^[0-9a-fA-F]{6}$/i)

export const htmlColorString = z.preprocess(
  htmlColorTransform,
  hexColorString.or(z.literal('transparent')).or(z.literal('00000000'))
)
export const htmlColorStringWithoutTransparent = z.preprocess(htmlColorTransform, hexColorString)

export const locale = z.enum(['de', 'en'])

export const localeSchema = z.object({
  locale,
})

export const localeWithSlugSchema = z.object({
  locale,
  slug,
})

export const emptyObject = z.object({})

export const receiverTypePathParam = z.enum(['projects', 'fundraising-events'])

export const fontWeight = z.coerce.number().int().min(0)

export const fontSize = z.coerce.number().int().min(1)

// rename -> widgetAlignment
export const alignment = z.enum(['left', 'center', 'right'])

export const darkMode = z.boolean()

export const widgetFont = z.string()

export const displayNumber = z.coerce.number().int().min(1)

export const widgetText = z.string()

export const shouldShowComment = z.boolean()

export const displayMode = z.enum(['list', 'loop'])

export const duration = z.coerce.number().min(0)

type FontFieldNames = 'Font' | 'FontWeight' | 'FontSize' | 'FontColor'
type FieldName<TPrefix extends string, TSuffix extends FontFieldNames> = `${TPrefix}${TSuffix}`
type FontFields<TPrefix extends string> = { [Key in FieldName<TPrefix, 'FontWeight'>]: typeof fontWeight } & {
  [Key in FieldName<TPrefix, 'FontSize'>]: typeof fontSize
} & { [Key in FieldName<TPrefix, 'FontColor'>]: typeof htmlColorString } & {
  [Key in FieldName<TPrefix, 'Font'>]: typeof widgetFont
}

export const getFontSchema = <T extends string>(prefix: T): FontFields<T> =>
  ({
    [`${prefix}Font`]: widgetFont,
    [`${prefix}FontWeight`]: fontWeight,
    [`${prefix}FontSize`]: fontSize,
    [`${prefix}FontColor`]: htmlColorString,
  }) as FontFields<T>

export const headlineFontSchema = getFontSchema('headline')
export const screenNameFontSchema = getFontSchema('screenName')
export const dataFontSchema = getFontSchema('data')
export const commentFontSchema = getFontSchema('comment')
export const optionFontSchema = getFontSchema('option')

export const progressBarSchema = {
  progressColor: htmlColorStringWithoutTransparent,
  progressBackgroundColor: htmlColorString,
}
